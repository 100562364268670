/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";



// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

//PRIME react components
//import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { Toast } from 'primereact/toast';
import { BlockUI } from 'primereact/blockui';

import SignIn from "layouts/authentication/sign-in";
import { PrivateRoute } from "common/PrivateRouter";
import global from "./context/global.context";

export default function App() {
  const toast = useRef(null);
  //const [menu] = useState(JSON.parse(localStorage.getItem('menu')));
  const [token] = useState(localStorage.getItem('token'));
  const [globalSession] = useState(JSON.parse(localStorage.getItem('globalSession')));
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [workItem, setWorkItem] = useState(null);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();



  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  const getRoutes = (allRoutes) => {

    const routesList = allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        //return <Route exact path={route.route} element={route.component} key={route.key} />;
        return <Route
          path={route.route}
          key={route.key}
          element={
            <PrivateRoute>
              {route.component}
            </PrivateRoute>
          }
        />
      }

      return null;
    });

    return routesList;
  }

  return (
    <>
      <Toast ref={toast} className="toast" />
      <BlockUI blocked={showBackDrop} fullScreen template={<i className="pi pi-lock" style={{ 'fontSize': '3rem' }} />} />
      <global.Provider value={{
        token,
        globalSession,
        setShowBackDrop,
        showBackDrop,
        toast,
        workItem,
        setWorkItem
      }}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="DALTON APP"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<SignIn />} />
          </Routes>
        </ThemeProvider>
      </global.Provider>
    </>
  );
}
