import { useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

//PRIMEREACT
import { Card } from 'primereact/card';

/**COMPONENTES */
import DetailMyFile from "layouts/myFiles/components/DetailMyFile/index";
import CustomerDetail from "./components/CustomerDetail";
import global from "context/global.context";
import { useFetch } from "customHooks/useFetch";
import clientReducer from "./reducer/client.reducer";

const init = () => {
    return {
        fullExcelInfo: null,
        client: null,
        notification: null,
        views: {
            mainView: false,
            detailClient: false,
            offer: false
        }
    }
}
const Clients = () => {

    const { workItem, toast, setShowBackDrop, token, globalSession } = useContext(global);
    const { invoke } = useFetch();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(clientReducer, [], init);

    useEffect(() => {
        if (!workItem) {
            toast.current.show({ severity: 'info', summary: 'Mensaje informativo', detail: 'Debe seleccionar un archivo primero', life: 3000 });
            return navigate("/myFiles");
        } else {
            getDetailFile(workItem.fileName);
        }
    }, [workItem]);

    useEffect(() => {
        if (state.notification) {
            console.log('se ejecuto ');
            toast.current.show(state.notification);
            //dispatch({ type: 'CLEAN_NOTIFICATION' });
        }
    }, [state.notification]);

    useEffect(() => {
        if (state.client) {
            dispatch({
                type: 'VIEWS_MANAGER',
                payload: {
                    propertie: 'mainView',
                    shows: false
                }
            });
            dispatch({
                type: 'VIEWS_MANAGER',
                payload: {
                    propertie: 'detailClient',
                    shows: true
                }
            });
        } else {
            dispatch({
                type: 'VIEWS_MANAGER',
                payload: {
                    propertie: 'mainView',
                    shows: true
                }
            });
            dispatch({
                type: 'VIEWS_MANAGER',
                payload: {
                    propertie: 'detailClient',
                    shows: false
                }
            });
        }
    }, [state.client]);

    const getDetailFile = async (fileName) => {
        setShowBackDrop(true);
        try {
            const rs = await invoke(`/getDataExcel?fileName=${fileName}`, null, token, null, 'JAVA');
            if (rs.ok) {
                const rsJson = await rs.json();
                dispatch({
                    type: 'SHOW_INFO',
                    payload: rsJson
                });
                toast.current.show({ severity: 'success', summary: 'Mensaje de éxito', detail: 'Consulta exitosa', life: 3000 });
            }
            else {
                if (rs.status === 403) {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'Su sesión termino', life: 3000 });
                } else {
                    const rsJson = await rs.json();
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: rsJson.message, life: 3000 });
                }
            }
            setShowBackDrop(false);
        } catch (error) {
            console.error(error);
            setShowBackDrop(false);
            toast.current.show({ severity: 'error', summary: 'Mensaje de error', detail: 'Ocurrio un problema inesperado', life: 3000 });
        }
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {(state.views.mainView && state.fullExcelInfo) &&
                <Card>
                    <DetailMyFile
                        fullExcelInfo={state.fullExcelInfo}
                        typeTable={'typeTwo'}
                        reducer={{ state, dispatch }}
                    />
                </Card>
            }
            {(state.views.detailClient && state.client) &&
                <CustomerDetail
                    reducer={{ state, dispatch }}
                    context={{ setShowBackDrop, toast, globalSession, token }}
                />
            }

        </DashboardLayout>
    );
}

export default Clients