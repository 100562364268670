import PropTypes from "prop-types";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from "react";
import { InputText } from 'primereact/inputtext';
import 'primeflex/primeflex.css';
import '../myFiles.css'

const DetailMyFile = (props) => {

    const [globalFilter, setGlobalFilter] = useState(null);
    const { reducer } = props;
    const { dispatch, state } = reducer;

    const header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Lista de clientes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );



    return (
        <div className="datatable-crud-demo">
            <div className="card">
                <DataTable
                    header={header}
                    value={props.fullExcelInfo}
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrado {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    globalFilter={globalFilter}
                    selectionMode="single"
                    selection={state.client}
                    onSelectionChange={e => dispatch({
                        type: 'SET_CLIENT',
                        payload: e.value
                    })}
                    dataKey="fileName">
                    <Column field="accredited" header="Acreditado"></Column>
                    <Column field="contract" header="Contrato"></Column>
                    <Column field="startDate" header="Inicio"></Column>
                    <Column field="dateVto" header="Vencimiento"></Column>
                    <Column field="initial" header="Inicial"></Column>
                    <Column field="residual" header="Residual"></Column>
                </DataTable>
            </div>
        </div>
    );
}


DetailMyFile.propTypes = {
    fullExcelInfo: PropTypes.array.isRequired,
    typeTable: PropTypes.string,
    reducer: PropTypes.object.isRequired,

}

export default DetailMyFile;