/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/wallpaper.png";
import logo from "assets/images/logo.png";

//CUSTOM COMPONENTS
import { useFetch } from "customHooks/useFetch";
import global from "context/global.context";

function Basic() {
  const { setShowBackDrop, toast } = useContext(global);
  const navigate = useNavigate();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const { invoke } = useFetch();


  const handleSetUser = (e) => setUser(e.target.value);
  const handleSetPwd = (e) => setPwd(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (user !== '' && pwd !== '') {
      setShowBackDrop(true);
      await login();
      setShowBackDrop(false);
    }
  };

  const login = async () => {

    try {
      const rq = {
        user,
        pwd
      };

      const rs = await invoke("/login", "POST", null, rq);
      const rsJson = await rs.json();
      if (rsJson?.status) {
        toast.current.show({ severity: 'warn', summary: 'Warn Message', detail: rsJson.message, life: 3000 });
      } else {
        if (rsJson.token) {
          localStorage.setItem('token', rsJson.token)
          const globalSesion = rsJson;
          delete globalSesion.token;
          localStorage.setItem('globalSession', JSON.stringify(globalSesion));
          toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Login Exitoso', life: 3000 });
          navigate('/clients');
          refreshPage();

        }
      }
    } catch (e) {
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: e.message, life: 3000 });
    }

  };

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={10} pb={5} px={10} style={{ alignSelf: 'center' }}mb={2} >
        <img src={logo} />
        </MDBox>
        <MDBox pt={5} pb={10} px={10}>
        <MDTypography variant="h4" fontWeight="medium" color="error" mb={2}>
           Iniciar Sesión
          </MDTypography>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput type="text" label="Usuario" fullWidth onChange={handleSetUser} required />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Contraseña" fullWidth onChange={handleSetPwd} required />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" type="submit" color="error" fullWidth>
               <h2>ENTRAR</h2>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
