const clientReducer = (initialState, action) => {

    switch (action.type) {
        case 'SHOW_INFO':
            return {
                ...initialState,
                fullExcelInfo: action.payload
            }
        case 'SET_CLIENT':
            return {
                ...initialState,
                client: createClient(action.payload)
            }
        case 'SET_OFFERS':
            return {
                ...initialState,
                offers: action.payload
            }
        case 'VIEWS_MANAGER':
            return {
                ...initialState,
                views: {
                    ...initialState.views,
                    [action.payload.propertie]: action.payload.shows
                }
            }
        case 'NOTIFICATION_MANAGER':
            return {
                ...initialState,
                notification: notificationMaker(action.payload, action.notiProps)
            }
        case 'RETURN_SCREEN':
            return {
                ...initialState,
                views: {
                    mainView: true,
                    detailClient: false
                },
                client:null
            }

        default:
            return { ...initialState };
    }

};

const notificationMaker = (status, notiProps) => {
    switch (status) {
        case 200:
            return {
                severity: 'success',
                summary: 'Mensaje de éxito',
                detail: notiProps ? notiProps.detail : 'Consulta exitosa',
                life: 3000
            }
        case 403:
            return {
                severity: 'warn',
                summary: 'Mensaje de advertencia',
                detail: 'Su sesión termino',
                life: 3000
            }
        case 404:
            return {
                severity: 'warn',
                summary: 'Mensaje de advertencia',
                detail: 'No se encontro el recurso',
                life: 3000
            }
        case 100:
            return {
                severity: 'error',
                summary: 'Mensaje de error',
                detail: 'Ocurrio un problema inesperado ' + notiProps.detail,
                life: 3000
            }
        case 500:
            return {
                severity: 'error',
                summary: 'Mensaje de error',
                detail: 'Ocurrion un problema en el servidor, contacte a soporte tecnico',
                life: 3000
            }

        default:
            return {
                severity: 'warn',
                summary: 'Mensaje de advertencia',
                detail: 'Ocurrio un problema al consultar',
                life: 3000
            }
    }

}


const createClient = (item) => {

    const tmpArray = [{
        startDate: item.startDate,
        expiration: item.expiration,
        mark: item.brand,
        model: item.model,
        version: item.version,
        year: item.year,
        initial: item.initial,
        residual: item.residual
    }];

    return {
        ...item,
        tmpArray
    }

}


export default clientReducer;