import { useEffect } from 'react';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

//PRIMEREACT
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';


import { useFetch } from "customHooks/useFetch";

import '../myFiles.css'


const UploadedFiles = (props) => {

    const { reducer, context } = props;
    const { setShowBackDrop, toast, globalSession, token, setWorkItem } = context;
    const { dispatch, state } = reducer;

    const { invoke } = useFetch();
    const navigate = useNavigate();

    useEffect(() => {
        getFiles();
    }, []);

    const getFiles = async () => {
        setShowBackDrop(true);
        try {
            const rs = await invoke(`/getMyFiles?id_user=${globalSession.id_user}`, null, token, null, 'JAVA');
            if (rs.ok) {
                const rsJson = await rs.json();
                dispatch({
                    type: 'MY_FILES_TABLE',
                    payload: rsJson
                });
                toast.current.show({ severity: 'success', summary: 'Mensaje de éxito', detail: 'Consulta Exitosa', life: 3000 });
            }
            else {
                if (rs.status === 403) {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'Su Sesión Terminó', life: 3000 });
                } else {
                    const rsJson = await rs.json();
                    console.log(rsJson);
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de Advertencia', detail: rsJson.message, life: 3000 });
                }
            }
            setShowBackDrop(false);
        } catch (error) {
            console.error(error);
            setShowBackDrop(false);
            toast.current.show({ severity: 'error', summary: 'Mensaje de error', detail: 'Ocurrio un problema inesperado', life: 3000 });
        }
    };



    const onRowSelect = async (event) => {
        setWorkItem(event.data);
        navigate('/clients');
    };


    return (
        <div>
            <Card>
                <DataTable
                    value={state.myFiles}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    selection={state.selectedFile}
                    onSelectionChange={e => dispatch({
                        type: 'SELECT_FILE',
                        payload: e.value
                    })}
                    dataKey="fileName"
                    onRowSelect={onRowSelect}
                    emptyMessage={'No se ha cargado ningún archivo'}>
                    <Column field="fileName" header="Nombre del Archivo"></Column>
                    <Column field="loadDate" header="Fecha de Carga"></Column>
                </DataTable>
            </Card>
        </div>
    );
};


UploadedFiles.propTypes = {
    reducer: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
}

export default UploadedFiles;