import { useReducer, useContext } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import 'primeflex/primeflex.css';


//COMPONENTS
import UploadedFiles from "./components/UploadedFiles/index";
import UploadDownload from "./components/UploadDownload/index";
import myFilesReducer from "./reducer/myFiles.reducer";
import global from "context/global.context";

const init = () => {
    return {
        fullExcelInfo: null,
        dialogs: {
            showConfirmation: false
        },
        myFiles: [],
        selectedFile: null,
    }
}
const MyFiles = () => {

    const { setShowBackDrop, toast, globalSession, token, setWorkItem } = useContext(global);
    const [state, dispatch] = useReducer(myFilesReducer, [], init);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="grid">
                <div className="col-12">
                    <UploadedFiles
                        reducer={{ state, dispatch }}
                        context={{ setShowBackDrop, toast, globalSession, token, setWorkItem }}
                    />
                </div>
                <div className="col">
                    <UploadDownload
                        reducer={{ state, dispatch }}
                        context={{ setShowBackDrop, toast, globalSession, token }}
                    />
                </div>
            </div>
        </DashboardLayout >
    );
};

export default MyFiles;