import { useState } from "react";
import PropTypes from "prop-types";

import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import 'primeflex/primeflex.css';
import '../myFiles.css'

//CUSTOM COMPONETS
import { useFetch } from "customHooks/useFetch";

const UploadDownload = (props) => {

    const { reducer, context } = props;
    const { setShowBackDrop, toast, globalSession, token } = context;
    const { dispatch, state } = reducer;
    const { invoke } = useFetch();
    const [fileName, setFileName] = useState();
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const chooseOptions = {
        label: 'Cargar Archivo',
        icon: 'pi pi-cloud-upload px-2',
        className: 'red p-button-sm'
    };

    const handleSelectFile = async (event) => {
        setShowBackDrop(true);
        try {
            let formData = new FormData();

            if (event.files[0]) {
                formData.append('anyExcel', event.files[0]);
                setFileName(event.files[0].name);
                const rs = await invoke('/api/readExcel', 'FILES', token, formData, 'NODE');
                if (rs.ok) {
                    const data = await rs.json();
                    toast.current.show({ severity: 'success', summary: 'Mensaje de éxito', detail: 'Se leyo correctamente el archivo', life: 3000 });
                    const info = data.fullExcelInfo[0].data;
                    dispatch({
                        type: 'ADD_FILE',
                        payload: info
                    });
                    dispatch({
                        type: 'DIALOG_MANAGER',
                        payload: {
                            propertie: 'showConfirmation',
                            shows: true
                        }
                    });
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'No pudo leer el archivo, favor de verificar la información', life: 3000 });
                }
            }
            setShowBackDrop(false);
        } catch (e) {
            console.error(e);
            setShowBackDrop(false);
            toast.current.show({ severity: 'error', summary: 'Mensaje de error', detail: 'Ocurrio un problema inesperado', life: 3000 });


        }
    };

    const onHide = () => {
        dispatch({
            type: 'DIALOG_MANAGER',
            payload: {
                propertie: 'showConfirmation',
                shows: false
            }
        });
        dispatch({ type: 'CLEAN_MEMORY' });
    };

    const onsave = async () => {
        try {
            setShowSaveButton(false);
            setShowBackDrop(true);
            const rq = state.fullExcelInfo.map(item => {
                return {
                    fkUser: globalSession.id_user,
                    fileName: fileName,
                    accredited: item.acreeditado,
                    year: item.anio,
                    contract: item.contrato,
                    email: item.correo1,
                    startDate: item.fecha_inicio,
                    dateVto: item.fecha_vto,
                    brand: item.marca,
                    model: item.modelo,
                    phone: item.telefono,
                    version: item.version,
                    vin: item.vin,
                    whatsapp: 'NO ESTA EN EL EXCEL',
                    initial: 'NO ESTA EN EL EXCEL',
                    residual: item.valor_residual,
                    dsnPurchaseValue: 'NO ESTA EN EL EXCEL',
                    dsnValueTake: 'NO ESTA EN EL EXCEL',
                    dsnValueDes: 'NO ESTA EN EL EXCEL',
                    filler_1: "a",
                    favorClient: 'NO ESTA EN EL EXCEL',
                    monthsOriginalLease: 'NO ESTA EN EL EXCEL',
                    currentMonthly: 'NO ESTA EN EL EXCEL',
                    incEspecial: 'NO ESTA EN EL EXCEL',
                    priceNewCar: 'NO ESTA EN EL EXCEL',
                    reduction: 'NO ESTA EN EL EXCEL',
                    vehicleOffered: 'NO ESTA EN EL EXCEL',
                    filler_2: "a",
                    newValidityLease: 'NO ESTA EN EL EXCEL',
                    monthlyNew: 'NO ESTA EN EL EXCEL',
                    initContinuousRentCurrent: 'NO ESTA EN EL EXCEL',
                    insurance: 'NO ESTA EN EL EXCEL',
                    carPlates: 'NO ESTA EN EL EXCEL',
                    leftoverCustomerAfterConversion: 'NO ESTA EN EL EXCEL',
                    filler_4: "a",
                    availabilityNewUnit: 'NO ESTA EN EL EXCEL',
                    interestRate: 'NO ESTA EN EL EXCEL',
                    porcentResidual: 'NO ESTA EN EL EXCEL'
                }
            });
            console.log(rq);
            const rs = await invoke('/loadData', 'POST', token, rq, 'JAVA');

            if (rs.ok) {
                //const data = await rs.json();
                toast.current.show({ severity: 'success', summary: 'Mensaje de éxito', detail: 'Datos almacenado correctamente', life: 3000 });

                dispatch({
                    type: 'DIALOG_MANAGER',
                    payload: {
                        propertie: 'showConfirmation',
                        shows: false
                    }
                });
                dispatch({ type: 'CLEAN_MEMORY' });
                await getFiles();
            } else {
                if (rs.status === 403) {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'Su sesión termino', life: 3000 });

                } else {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'Ocurrio un problema al guardar', life: 3000 });
                }
            }

            setShowBackDrop(false);


        } catch (error) {
            console.error(error);
            setShowBackDrop(false);
            toast.current.show({ severity: 'error', summary: 'Mensaje de error', detail: 'Ocurrio un problema inesperado', life: 3000 });
        }
    };

    const handleButton = () => {
        setShowSaveButton(true);
        setTimeout(
            () => {
                setShowSaveButton(false);
            }, 5000);

    };


    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />

                {showSaveButton ?
                    <Button label="Click para confirmar" icon="pi pi-check" onClick={() => onsave()} autoFocus />
                    :
                    <Button label="Guardar" icon="pi pi-check" onClick={() => handleButton()} autoFocus />
                }
            </div>
        );
    };


    const getFiles = async () => {
        setShowBackDrop(true);
        try {
            const rs = await invoke(`/getMyFiles?id_user=${globalSession.id_user}`, null, token, null, 'JAVA');
            if (rs.ok) {
                const rsJson = await rs.json();
                dispatch({
                    type: 'MY_FILES_TABLE',
                    payload: rsJson
                });
                toast.current.show({ severity: 'success', summary: 'Mensaje de éxito', detail: 'Consulta Exitosa', life: 3000 });
            }
            else {
                if (rs.status === 403) {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'Su Sesión terminó', life: 3000 });

                } else {
                    toast.current.show({ severity: 'warn', summary: 'Mensaje de advertencia', detail: 'Ocurrió un problema al guardar', life: 3000 });
                }
            }
            setShowBackDrop(false);
        } catch (error) {
            console.error(error);
            setShowBackDrop(false);
            toast.current.show({ severity: 'error', summary: 'Mensaje de error', detail: 'Ocurrio un problema inesperado', life: 3000 });
        }
    }

    const header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Lista de clientes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <>
            {state.dialogs.showConfirmation &&
                <Dialog
                    header="¿Es Correcta la información?"
                    visible={state.dialogs.showConfirmation}
                    style={{ width: '50vw' }}
                    footer={renderFooter}
                    onHide={() => onHide()}>
                    <div className="datatable-crud-demo">
                        <div className="card">
                            <DataTable
                                header={header}
                                value={state.fullExcelInfo}
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrado {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                paginator
                                globalFilter={globalFilter}>
                                <Column field="acreeditado" header="Acreditado"></Column>
                                <Column field="celular" header="Celular"></Column>
                                <Column field="contrato" header="Fecha inicio"></Column>
                                <Column field="fecha_vto" header="Fecha vto"></Column>
                                <Column field="plazo_arrendamiento_anios" header="Plazo arren."></Column>
                                <Column field="marca" header="Marca"></Column>
                                <Column field="modelo" header="Modelo"></Column>
                                <Column field="version" header="Versión"></Column>
                                <Column field="anio" header="Año"></Column>
                                <Column field="agencia" header="agencia"></Column>
                                <Column field="promotor" header="Promotor"></Column>
                                <Column field="agencia_asignada" header="Agencia asignada"></Column>
                                <Column field="vin" header="VIN"></Column>
                                <Column field="valor_residual" header="Valor residual"></Column>
                                <Column field="telefono" header="Telefono"></Column>
                                <Column field="correo1" header="Correo"></Column>
                                <Column field="renta" header="Renta"></Column>
                            </DataTable>
                        </div>
                    </div>
                </Dialog>
            }
            <div className="button-demo">
                <div className="template">
                    <div className="flex flex-wrap align-items-center justify-content-center">
                        <FileUpload
                            name="anyExcel"
                            mode="basic"
                            chooseOptions={chooseOptions}
                            auto
                            onSelect={handleSelectFile}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            maxFileSize={1000000} />
                        <Button className="red p-button-sm" label="Descargar Layout" icon="pi pi-download px-2">
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
};

UploadDownload.propTypes = {
    reducer: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
}

export default UploadDownload;