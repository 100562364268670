


const myFilesReducer = (initialState, action) => {

    switch (action.type) {
        case 'ADD_FILE':
            return {
                ...initialState,
                fullExcelInfo: action.payload,
            };
        case 'CLEAN_MEMORY':
            return {
                ...initialState,
                fullExcelInfo: null
            }
        case 'DIALOG_MANAGER':
            console.log({
                ...initialState,
                dialogs: {
                    ...initialState.dialogs,
                    [action.payload.propertie]: action.payload.shows
                }
            });
            console.log('TERMINO');
            return {
                ...initialState,
                dialogs: {
                    ...initialState.dialogs,
                    [action.payload.propertie]: action.payload.shows
                }
            }
        case 'MY_FILES_TABLE':
            return {
                ...initialState,
                myFiles: action.payload
            }
        case 'SELECT_FILE':
            return {
                ...initialState,
                selectedFile: action.payload
            }
        default:
            return { ...initialState };
    }

};


export default myFilesReducer;