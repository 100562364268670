export const useFetch = () => {


    const invoke = (url, typeFetch, token, data, typeURL) => {
        const opt = createTypeFetch(typeFetch, token, data);
        let baseURL;
        switch (typeURL) {
            case 'NODE':
                baseURL = process.env.REACT_APP_BASE_URL_API_NODE;
                break;
            default:
                baseURL = process.env.REACT_APP_BASE_URL_API;
                break;
        }
        console.log(baseURL);
        return fetch(`${baseURL}${url}`, opt);
    };

    const createTypeFetch = (type, token, data) => {
        console.log(`buscando accion ${type}`);
        switch (type) {
            case 'POST':
                return {
                    method: 'POST',
                    body: JSON.stringify(data),
                    mode: 'cors',
                    headers: {
                        'accept': '*/*',
                        "content-security-policy": `default-src 'none'`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        ...(token) && { Authorization: `Bearer ${token}` }
                    }
                };
            case 'PUT':
                return {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Basic ' + btoa(process.env.REACT_APP_CLIENT_ID + ':' + process.env.REACT_APP_KEY)
                    }
                };
            case 'DELETE':
                return {
                    method: 'DELETE',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Basic ' + btoa(process.env.REACT_APP_CLIENT_ID + ':' + process.env.REACT_APP_KEY)
                    }
                };
            case 'FILES':
                return {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: data
                }
            default:
                return {
                    method: 'GET',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    }
                };
        }

    };
    return {
        invoke
    };
};

