
import { useState } from "react";

import PropTypes from "prop-types";

//PrimeReact components
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primeflex/primeflex.css';
import './formOffers.css';

//CUSTOM COMPONENTS
import { useFetch } from "customHooks/useFetch";


const Offers = (props) => {

  const { invoke } = useFetch();

  const { reducer, context, index, info } = props;
  const { setShowBackDrop, token } = context;
  const { state } = reducer;

  console.log(index);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [form, setForm] = useState({
    tipo_credito: 1,
    plazo_meses: 12,
    tasa_int: '0.18/12',
    mensualidad_con_iva: 10000,
    contrato: state.client.contract
  });

  console.log(state.client.contract);

  const invokeOp1 = async (e) => {
    e.preventDefault();
    setShowBackDrop(true);
    setLoading(true);
    try {
      const rs = await invoke("/python/operation2", "POST", token, form);
      const rsJson = await rs.json();
      setData(rsJson);
    } catch (e) {
      console.error(e);
    } finally {
      setShowBackDrop(false);
      setLoading(false);
    }
  }


  return (

    <>
      <Card>
        <div className="form-demo">
          <div className="flex justify-content-center">
            <div className="card">
              <h4 className="text-center">INGRESA LOS VALORES</h4>
              <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
              <form className="p-fluid" onSubmit={(e) => invokeOp1(e)}>
                <div className="field">
                  <span className="p-float-label">
                    <InputText id="tipoCredito" value={form.tipo_credito} name="tipoCredito" autoFocus onChange={(e) => setForm({ ...form, tipo_credito: e.target.value })} />
                    <label htmlFor="tipoCredito" >Tipo de Credito*</label>
                  </span>
                </div>
                <div className="field">
                  <span className="p-float-label">
                    <InputText id="plazoMeses" value={form.plazo_meses} name="plazoMeses" onChange={(e) => setForm({ ...form, plazo_meses: e.target.value })} />
                    <label htmlFor="plazoMeses" >Plazo de Meses*</label>
                  </span>
                </div>
                <div className="field">
                  <span className="p-float-label">
                    <InputText id="tasa" name="tasa" value={form.tasa_int} onChange={(e) => setForm({ ...form, tasa_int: e.target.value })} />
                    <label htmlFor="tasa" >Tasa de Interés%*</label>
                  </span>
                </div>
                <div className="field">
                  <span className="p-float-label">
                    <InputText id="mensu" name="mensu" value={form.mensualidad_con_iva} onChange={(e) => setForm({ ...form, mensualidad_con_iva: e.target.value })} />
                    <label htmlFor="mensu" >Mensualidd con I.V.A*</label>
                  </span>
                </div>
                <div className="field">
                  <span className="p-float-label">
                    <InputText id="contrato" name="contrato" disabled value={form.contrato} onChange={(e) => setForm({ ...form, contrato: e.target.value })} />
                    <label htmlFor="contrato" >Numero de Contrato*</label>
                  </span>
                </div>
                <Button type="submit" label="Calcular" className="mt-2 p-button-danger" />
              </form>
            </div>
          </div>
        </div>
      </Card>

      {data &&
        <>

          <div className="col-12">
            <div className="surface-0">
              <div className="text-600 font-bold text-6xl mb-4 text-center">Resultado</div>
              <div className="grid">
                <div className="col-12">
                  <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full" style={{ borderRadius: '6px' }}>
                      <div className="text-900 font-medium text-xl mb-2">Oferta {index}</div>
                      <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                      <div className="flex align-items-center justify-content-center">
                        <span className="font-bold text-2xl text-900">{info.modelo}, {info.version}</span>
                      </div>
                      <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                      <ul className="list-none p-0 m-0 flex-grow-1">
                        <li className="flex align-items-center justify-content-center">
                          <span>Residual de Vencimiento:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center">
                          <span>$ {info.residual_vencimiento}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Toma Seminuevo:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center">
                          <span>$ {info.valor_de_toma}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Saldo de Toma:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center">
                          <span>$ {info.saldo_de_toma}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Pago Incial Nueva Unidad:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center">
                          <span>$ {info.com_por_apertura}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Pago cliente o saldo cliente:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center"> 
                        <span>$ {info.paga_cliente_o_saldo_cliente}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Precio de vehículo nuevo:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center">
                        <span>$ {info.precio_auto_nuevo}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Monto a financiar:</span>
                        </li>
                        <li className="flex align-items-center mb-3 justify-content-center">
                        <span>$ {info.a_financiar}</span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                          <span>Fecha: </span>
                        </li>
                        <li className="flex align-items-center justify-content-center">
                        <span>{info.fecha_inicio_cred}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid">
            {index === 1 &&
              <div className="col-12">
                <Card>
                  <DataTable value={data.offers1} loading={loading} responsiveLayout="scroll">
                    <Column field="modelo" header="Modelo"></Column>
                    <Column field="saldo" header="Saldo"></Column>
                    <Column field="restante" header="Restante"></Column>
                    <Column field="pago" header="Pago"></Column>
                    <Column field="fecha" header="Fecha"></Column>
                  </DataTable>
                </Card>
              </div>
            }
            {index === 2 &&
              <div className="col-12">
                <Card>
                  <DataTable value={data.offers2} loading={loading} responsiveLayout="scroll">
                    <Column field="modelo" header="Modelo"></Column>
                    <Column field="saldo" header="Saldo"></Column>
                    <Column field="restante" header="Restante"></Column>
                    <Column field="pago" header="Pago"></Column>
                    <Column field="fecha" header="Fecha"></Column>
                  </DataTable>
                </Card>
              </div>
            }
            {index === 3 &&
              <div className="col-12">
                <Card>
                  <DataTable value={data.offers3} loading={loading} responsiveLayout="scroll">
                    <Column field="modelo" header="Modelo"></Column>
                    <Column field="saldo" header="Saldo"></Column>
                    <Column field="restante" header="Restante"></Column>
                    <Column field="pago" header="Pago"></Column>
                    <Column field="fecha" header="Fecha"></Column>
                  </DataTable>
                </Card>
              </div>
            }
          </div>

        </>
      }

    </>
  );
}


Offers.propTypes = {
  reducer: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  info: PropTypes.object.isRequired
}

export default Offers;
