import { useEffect, useState } from "react";

import PropTypes from "prop-types";

/**PRIMEREACT */
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import 'primeflex/primeflex.css';

//CUSTOM COMPONENTS
import { useFetch } from "customHooks/useFetch";
import Offers from "layouts/offers";
import 'layouts/myFiles/components/myFiles.css'


const CustomerDetail = (props) => {

    const { reducer, context } = props;
    const { setShowBackDrop, token } = context;
    const { state, dispatch } = reducer;
    const { invoke } = useFetch();

    const [index, setIndex] = useState(null);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        getDetail();

    }, []);


    const getDetail = async () => {
        setShowBackDrop(true);
        try {
            const rs = await invoke(`/getById?id=${state.client.id_row}`, null, token, null, 'JAVA');
            const rsJson = await rs.json();
            dispatch({
                type: 'SET_CLIENT',
                payload: rsJson
            });
            dispatch({
                type: 'NOTIFICATION_MANAGER',
                payload: rs.status,
                notiProps: rs.ok && {
                    detail: 'Se consultó correctamente los datos del cliente'
                }
            });

            dispatch({
                type: 'SET_OFFERS',
                payload: (await invokeOperation1()).data
            });

        } catch (error) {
            console.error(error);
            dispatch({
                type: 'NOTIFICATION_MANAGER',
                payload: 100,
                notiProps: {
                    detail: error.message
                }
            });
        } finally {
            setShowBackDrop(false);
        }
    };

    const invokeOperation1 = async () => {
        const rq = {
            tipo_credito: 1,
            plazo_meses: 12,
            tasa_int: "0.18/12",
            mensualidad_con_iva: 10000,
            contrato: state.client.contract
        }

        const rs = await invoke(`/python/operation1`, 'POST', token, rq, 'JAVA');
        const rsJson = await rs.json();
        dispatch({
            type: 'NOTIFICATION_MANAGER',
            payload: rs.status,
            notiProps: rs.ok && {
                detail: 'Se cargaron la ofertas'
            }
        });

        return rsJson;
    }


    const headerTemplate = () => {
        return (
            <>
                <div className="grid">
                    <div className="col-12">
                        
                    </div>
                   
                </div>
            </>
        );
    };


    const headeroffersTemplate = (indexOffer, data) => {
        return (
            <>
                <div className="grid">
                    <div className="col-9">
                        <h2 ml-3>{`Oferta ${indexOffer}`}</h2>
                    </div>
                    <div className="col-3">
                        <Button icon="pi pi-user-edit" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => {
                            dispatch({
                                type: 'VIEWS_MANAGER',
                                payload: {
                                    propertie: 'offer',
                                    shows: true
                                }
                            });
                            setIndex(indexOffer);
                            setInfo(data);
                        }} />
                    </div>
                </div>
            </>
        );
    };

    const handleReturn = () => {
        dispatch({
            type: 'RETURN_SCREEN'
        });
    };

    const closeDialog = () => {
        dispatch({ type: 'VIEWS_MANAGER', payload: { propertie: 'offer', shows: false } });
        setIndex(null);
        setInfo(null);
    }

    return (
        <>
            <Button icon="pi pi-arrow-left" className="p-button-rounded my-5 p-button-danger" onClick={handleReturn} tooltip={"Regresar"} />
            <Dialog header="Header" visible={state.views.offer} style={{ width: '50vw' }} onHide={closeDialog}>
                {(index && info) &&
                    <Offers
                        reducer={{ state, dispatch }}
                        context={{ setShowBackDrop, token }}
                        index={index}
                        info={info}
                    />
                }

            </Dialog>
            <div className="grid">
                <div className="col-12 my-5">
                    <Card header={headerTemplate}>
                        <div className="grid">
                            <div className="col-9">
                                <h2>CLIENTE: {state.client.accredited}</h2>
                            </div>
                            <div className="col-3 justify-content-right">
                                <h5>CONTRATO: {state.client.contract}</h5>
                                <h5>VIN: {state.client.vin}</h5>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col">
                                <div className="card">
                                    <DataTable value={state.client.tmpArray} responsiveLayout="scroll">
                                        <Column field="startDate" header="Inicio"></Column>
                                        <Column field="expiration" header="Vencimiento"></Column>
                                        <Column field="mark" header="Marca"></Column>
                                        <Column field="model" header="Modelo"></Column>
                                        <Column field="version" header="Versión"></Column>
                                        <Column field="year" header="Año"></Column>
                                        <Column field="initial" header="Inicial"></Column>
                                        <Column field="residual" header="Residual"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                {/* <div className="col-12 my-5">
                    <Card >
                        <h1>Vehiculo ofertado</h1>
                        <h1>Informacion pendiente</h1>
                    </Card>
                </div> */}
                <div className="col-12 my-5">
                    {
                        state.offers &&
                        <div className="grid">
                            <div className="col">
                                <Card header={() => headeroffersTemplate(1, state.offers[0])}>
                                    <h4 className="text-center">{state.offers[0].marca} {state.offers[0].modelo} {state.offers[0].version}  {state.offers[0].anio}</h4>
                                    <h4 className="text-center">Inicial: ${state.offers[0].a_financiar}</h4>
                                </Card>
                            </div>
                            <div className="col">
                                <Card header={() => headeroffersTemplate(2, state.offers[1])}>
                                    <h4 className="text-center">{state.offers[1].marca} {state.offers[1].modelo} {state.offers[1].version} {state.offers[1].anio}</h4>
                                    <h4 className="text-center">Inicial: ${state.offers[1].a_financiar}</h4>
                                </Card>
                            </div>
                            <div className="col">
                                <Card header={() => headeroffersTemplate(3, state.offers[2])}>
                                    <h4 className="text-center">{state.offers[2].marca} {state.offers[2].modelo} {state.offers[2].version} {state.offers[2].anio}</h4>
                                    <h4 className="text-center">Inicial: ${state.offers[2].a_financiar}</h4>
                                </Card>
                            </div>
                        </div>
                    }
                </div>
                <div className="button-demo">
                    <div className="template">
                        <div className="flex flex-wrap align-items-center justify-content-center">

                            <Button className="red p-button-sm" label="Ver propuestas" icon="pi pi-download px-2"></Button>
                            <Button className="red p-button-sm" label="Enviar propuesta" icon="pi pi-download px-2"></Button>

                            <Button className="red p-button-sm" label="Ver Seguimiento" icon="pi pi-download px-2"></Button>
                            <Button className="red p-button-sm" label="Registrar llamada" icon="pi pi-download px-2"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

CustomerDetail.propTypes = {
    reducer: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
}

export default CustomerDetail;